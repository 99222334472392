import { Heading, Text } from '~/vinovest-design-system/components';

import { WhatToExpectCardConstants, WhatToExpectTableConstants } from './WhatToExpectConstants';
import { WhatToExpectList } from './WhatToExpectList';
import { useWindowSize } from '~/hooks/useWindowSize';

export const WhatToExpectContainer = () => {
    const { isDesktop, isMobile, isTablet } = useWindowSize();
    const smallScreen = isMobile || isTablet;
    return (
        <div className={'h-full sm:bg-transparent max-w-[1100px] relative mx-auto z-[5] p-[20px 0]'}>
            <div className={'flex justify-center flex-col md:static'}>
                <Heading level={'h2'} classnames={'xs:!text-[32px] lg:!text-[45px] text-center !leading-loose'}>
                    What to Expect
                </Heading>
            </div>
            <div className={'rounded-[20px] mb-[40px] px-2 md:px-0 py-[40px] bg-white'}>
                <Heading level={'h3'} type={'h4'}>
                    Sample Cask Performance
                </Heading>
                <Text classnames={'max-w-[694px] mx-auto my-[30px]'}>
                    Below is the sample timeline of a $1500 Kentucky Bourbon cask based on the Wholesale Market Insured
                    Value. It reflects the anticipated growth of a cask's resale price.
                </Text>
                {smallScreen && (
                    <table className={'text-platinum-800 w-full text-left align-top'}>
                        {WhatToExpectTableConstants.map((row) => {
                            return (
                                <tbody className={'mb-[40px]'} key={row.id}>
                                    <tr>
                                        <th
                                            colSpan={2}
                                            className={`border-[1px] border-gray-300 p-[10px] text-[18px] font-semibold text-center ${row.isOptimal && 'text-green-600'}`}
                                        >
                                            {row.year}
                                            {row.isOptimal && (
                                                <span className={'block text-[12px]'}>Optimal Maturity</span>
                                            )}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th
                                            className={
                                                'border-[1px] border-gray-300 p-[10px] bg-gray-200 text-[13px] font-normal'
                                            }
                                        >
                                            Est. Cask
                                            <br /> Value
                                        </th>
                                        <td className={'border-b-[1px] border-r-[1px]  border-gray-300 p-[10px]'}>
                                            {row.caskValue}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            className={
                                                'border-[1px] border-gray-300 p-[10px] bg-gray-200 text-[13px] font-normal'
                                            }
                                        >
                                            Est. Annual Storage <br />& Insurance Cost
                                        </th>
                                        <td className={'border-b-[1px] border-r-[1px]  border-gray-300 p-[10px]'}>
                                            {row.storageCost}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            className={
                                                'border-[1px] border-gray-300 p-[10px] bg-gray-200 text-[13px] font-normal'
                                            }
                                        >
                                            Est. Value Net of <br />
                                            Storage & Insurance
                                        </th>
                                        <td className={'border-b-[1px] border-r-[1px]  border-gray-300 p-[10px]'}>
                                            {row.netValue}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            className={
                                                'border-[1px] border-gray-300 p-[10px] bg-gray-200 text-[13px] font-normal'
                                            }
                                        >
                                            Est. Total
                                            <br /> Return
                                        </th>
                                        <td
                                            className={
                                                'border-b-[1px] border-r-[1px]  border-gray-300 p-[10px] text-green-600'
                                            }
                                        >
                                            {row.totalReturn}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th
                                            className={
                                                'border-b-[1px] border-r-[1px]  border-gray-300  p-[10px] bg-gray-200 text-[13px] font-normal'
                                            }
                                        >
                                            Est. Annualized
                                            <br /> Return
                                        </th>
                                        <td
                                            className={
                                                'border-b-[1px] border-r-[1px]  border-gray-300  p-[10px] text-green-600'
                                            }
                                        >
                                            {row.annualizedRetrun}
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })}
                    </table>
                )}
                {isDesktop && (
                    <table className={'text-platinum-800 w-full text-left align-top'}>
                        <thead>
                            <th
                                className={
                                    'border-b-[1px] border-r-[1px] border-t-[1px] border-gray-300 p-[20px] bg-gray-200 text-[15px] font-normal'
                                }
                            >
                                Year
                            </th>
                            <th
                                className={
                                    'border-b-[1px] border-r-[1px] border-t-[1px] border-gray-300 p-[20px] bg-gray-200 text-[15px] font-normal'
                                }
                            >
                                Est. Cask
                                <br /> Value
                            </th>
                            <th
                                className={
                                    'border-b-[1px] border-r-[1px] border-t-[1px] border-gray-300 p-[20px] bg-gray-200 text-[15px] font-normal'
                                }
                            >
                                Est. Annual Storage <br />& Insurance Cost
                            </th>
                            <th
                                className={
                                    'border-b-[1px] border-r-[1px] border-t-[1px] border-gray-300 p-[20px] bg-gray-200 text-[15px] font-normal'
                                }
                            >
                                Est. Value Net of <br />
                                Storage & Insurance
                            </th>
                            <th
                                className={
                                    'border-b-[1px] border-r-[1px] border-t-[1px] border-gray-300 p-[20px] bg-gray-200 text-[15px] font-normal'
                                }
                            >
                                Est. Total
                                <br /> Return
                            </th>
                            <th
                                className={
                                    'border-b-[1px] border-t-[1px] border-gray-300 p-[20px] bg-gray-200 text-[15px] font-normal'
                                }
                            >
                                Est. Annualized
                                <br /> Return
                            </th>
                        </thead>
                        <tbody>
                            {WhatToExpectTableConstants.map((row) => {
                                return (
                                    <tr key={row.id}>
                                        <td className={`border-b-[1px] border-r-[1px] border-gray-300 p-[20px]`}>
                                            {row.year}
                                            {row.isOptimal && (
                                                <span
                                                    className={`block text-[12px] ${row.isOptimal && 'text-green-600'}`}
                                                >
                                                    Optimal Maturity
                                                </span>
                                            )}
                                        </td>
                                        <td className={'border-b-[1px] border-r-[1px] border-gray-300 p-[20px]'}>
                                            {row.caskValue}
                                        </td>
                                        <td className={'border-b-[1px] border-r-[1px] border-gray-300 p-[20px]'}>
                                            {row.storageCost}
                                        </td>
                                        <td className={'border-b-[1px] border-r-[1px] border-gray-300 p-[20px]'}>
                                            {row.netValue}
                                        </td>
                                        <td
                                            className={
                                                'border-b-[1px] border-r-[1px] border-gray-300 p-[20px] text-green-600'
                                            }
                                        >
                                            {row.totalReturn}
                                        </td>
                                        <td className={'border-b-[1px] border-gray-300 p-[20px] text-green-600'}>
                                            {row.annualizedRetrun}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                <Text classnames={'text-12 !text-gray-500 max-w-[694px] mx-auto mt-[20px]'}>
                    We source new make whiskey casks between $1500 - $1750 per cask. If we purchase casks less than
                    $1750, the remaining cash will be credited back to your account. The values and returns shown in the
                    table are estimated based on historical data and current insured values. Actual outcomes may vary
                    based on market conditions, cask quality, and other factors.
                </Text>
            </div>
            <div className={'sm:border bg-white rounded-[20px] mb-[40px] py-[40px] px-2 md:px-0'}>
                <Heading level={'h3'} type={'h4'}>
                    Active Rebalancing
                </Heading>
                <Text classnames={'max-w-[694px] mx-auto my-[30px]'}>
                    Our team is constantly scouting the market for the best exit opportunities. Sometimes there are
                    opportunities to sell your whiskey early at a substantial gain. Here we found the opportunity to
                    sell a 1 year old whiskey 30.74% above its initial purchase price:
                </Text>
                <WhatToExpectList cards={WhatToExpectCardConstants} />
                <Text classnames={'text-12 !text-gray-500 max-w-[694px] mx-auto mt-[20px]'}>
                    Past exits are not indicative of future results. All results are net of fees.
                </Text>
            </div>
        </div>
    );
};
